
import { defineComponent, ref, onBeforeMount, computed ,watchEffect} from 'vue'
import { useToast } from "primevue/usetoast";

import ResultadosCard from '@/components/ResultadosCard.vue'
import axios from '@/plugins/axios';
import moment from 'moment';

import { useStore } from 'vuex';
import { jsPDF } from 'jspdf';

import Pac from '@/modules/Pac'


import { imprimirHC } from '@/hooks/useImpresion';

const PUBLIC_ARCHIVE_TYPES = ["LAB", "RX", "TC", "EC", "QX", "AP", "PAP", "RMN",'EDC','ESC','EVC','EDV','EDA','HOL','MAP','ERC','TCM', 'CEN'];
type PublicArchiveTypes = "LAB" | "RX" | "TC" | "EC" | "QX" | "AP" | "PAP" | "RMN" | 'EDC'| 'ESC'| 'EVC'| 'EDV'| 'EDA'| 'HOL'| 'MAP'| 'ERC'| 'TCM' | 'CEN';

export default defineComponent({
    components:{
        ResultadosCard
    },
    setup: () => {
        const toast = useToast();
        const store = useStore();
        const contenedorResultados = ref<HTMLDivElement>();
        const resultadoFormActive = ref(false);
        
        const medicos = ref([{nombre:'Patricio Esponja'}]);
        const medicoSeleccionado = ref(null);
        const especialidades = ref([{nombre:'Pediatria'}]);
        const especialidadSeleccionada = ref(null);
        const fecha = ref([new Date()]);

        const tipos = ref(['Todo', 'Estudios', 'Imagenes']);
        const tipoSeleccionado = ref('Todo');

        
        const evolucionesList = ref();
        const evolucionSeleccionada = ref();
        const pagina = ref(1);
        const itemsPorPagina = ref(10);
        const cantidadResultados = ref();
        
        
        const handleTipoChange = () => {
            pagina.value = 1;
            obtenerRegistros(true)
        }


        const handleVerClick = async (item:any) =>{
            try {

                const pdfjs =  new jsPDF({
                    format: 'a4',
                    unit:'pt',
                    hotfixes:["px_scaling"]
                });
                
                const result = await axios.get(`/historia-clinica-get-registros/${item._id}`)
                evolucionSeleccionada.value = result.data;

                if(evolucionSeleccionada.value.datos?.file?.location){
                    downloadArchive(evolucionSeleccionada.value.datos.file)
                    return;
                }
                
                

                if(evolucionSeleccionada.value.tipo === 'PAC'){
                    const pac = await Pac.getInformeHC(evolucionSeleccionada.value);
                    await downloadPacArchive(pac);
                    return;
                }

                if(!evolucionSeleccionada.value.medico?.logofirma){
                    toast.add({severity:'warn',summary:'Atencion',detail:'Resultado sin firma.',life:1500})
                    return;
                }
                
                await imprimirHC(evolucionSeleccionada.value,pdfjs, store.state.configuracionPortal.cliente);
                pdfjs.save(`${evolucionSeleccionada.value.datos.nombre} - ${moment(evolucionSeleccionada.value.datos.fecha).format('DD_MM_YYYY')}.pdf`);
            } catch (error) {
                console.log(error);
            }
        }

        const handleVisualizar = async (item:any) => {
            const result = await axios.get(`/historia-clinica-get-registros/${item._id}`)
            evolucionSeleccionada.value = result.data;
            const pac = await Pac.getInformeHC(evolucionSeleccionada.value);
            evolucionSeleccionada.value.pac = pac;
            resultadoFormActive.value = true;
        }

        const downloadArchive = (file:any) => {
            let link = document.createElement('a');
            link.href = file.location;
            link.download = file.name;
            link.click();
        }


        const downloadPacArchive = async (estudio:any) => {
            const { data } = await axios.get('get-url?url=' + estudio.estudio.informeurl, { responseType: 'blob' })
            if(data.size < 1000) {
                toast.add({severity:'warn',summary:'Atencion',detail:'Estudio no disponible.',life:1500})
            } else {
                let reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onloadend = () => {
                    let base64data = reader.result;
                    let link = document.createElement('a');
                    link.href = base64data as string;
                    link.download = `Diagnostico Por Imagen` + '.pdf';
                    link.click();
                }
            }
        }

        const obtenerRegistros = async (isNew = false) => {
            try {
                
                const archivesNames : Record<PublicArchiveTypes,string> = {
                    LAB:'Laboratorio',
                    RX:'Radiologia',
                    TC:'Tomografia',
                    EC:'Ecografia',
                    QX:'Parte Quirurgico',
                    AP:'Anatomia Patologica',
                    PAP:'Papanicolau',
                    RMN:'Resonancia Magnetica Nuclear',
                    EDC:'Eco Doppler Cardiaco',
                    ESC:'Eco Stress Cardiaco',
                    EVC:'Eco Doppler de vasos de cuello',
                    EDV:'Eco Doppler venoso',
                    EDA:'Eco Doppler arterial',
                    HOL:'Holter',
                    MAP:'Presurometria (MAPA)',
                    ERC:'Ergonometria computarizada',
                    TCM:'Test de caminata de 6 minutos',
                    CEN:'Centellograma',
                }

                

                const filtrosTipo : Record<string,any> = {
                    Todo: {$or:[{tipo:'HC'},{tipo:'PAC'},...PUBLIC_ARCHIVE_TYPES.map((tipo:string) => ({tipo}))]},
                    Estudios: {$or:[{tipo:'HC'}, ...PUBLIC_ARCHIVE_TYPES.map((tipo:string) => ({tipo}))]},
                    Imagenes: {tipo:'PAC'}
                }

                const query = encodeURIComponent(JSON.stringify({...(filtrosTipo[tipoSeleccionado.value] || {}),'datos.isPublicable':true} || {}));
                
                const { data } = await axios.get(`/historia-clinica-get-registros/${store.state.pacienteLogueado.id}/${pagina.value}/${itemsPorPagina.value}?query=${query}`);

                for(let dato of data.registros){
                    if(PUBLIC_ARCHIVE_TYPES.includes(dato.tipo)){
                        dato.datos.nombre = archivesNames[dato.tipo as PublicArchiveTypes];
                    }
                    if(dato.tipo === 'PAC'){
                        dato.datos.nombre = 'Diagnostico Por Imagen';
                    }
                }
                
                
                evolucionesList.value = evolucionesList.value && !isNew ? [...evolucionesList.value, ...data.registros] : data.registros;
                cantidadResultados.value = data.count;
                especialidades.value = data.especialidad;
                medicos.value = data.medicos;
                
            } catch (error) {
                console.log(error);
            }
        }

        const cantidadPaginas = computed(() => {
            return Math.ceil(cantidadResultados.value / itemsPorPagina.value);
        });

        watchEffect(() => {
            
            if(contenedorResultados.value){
                contenedorResultados.value.removeEventListener('scroll', eventoDeScroll);
                contenedorResultados.value.addEventListener('scroll', eventoDeScroll);
            }
        })
        
        onBeforeMount(() => {
            obtenerRegistros();
        })


        


        const eventoDeScroll = (e:any) => {
            const { target:{scrollTop, offsetHeight, scrollHeight} } = e;
            
            if(scrollTop + offsetHeight >= scrollHeight){
               
                pagina.value += 1;
                if(cantidadPaginas.value >= pagina.value){
                    
                    obtenerRegistros();
                }
                
                
            }
        }

        
        
        
        
        return {
            contenedorResultados,
            resultadoFormActive,
            medicos,
            medicoSeleccionado,
            especialidades,
            especialidadSeleccionada,
            fecha,
            evolucionesList,
            evolucionSeleccionada,
            tipos,
            tipoSeleccionado,
            handleVerClick,
            handleTipoChange,
            handleVisualizar,
        }
    }

})
